<template>
  <v-form>
    <v-card style="background-color: #111; border: 1px solid #333">
      <v-card-title>
        <h3 class="text-body-2">Add Existing Deal</h3>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          class="mb-2"
          single-line
          hide-details
        />

        <v-data-table
          @click:row="handle_selected_deal"
          :headers="header_deals"
          :items="unassociated_deals"
          :items-per-page="-1"
          :search="search"
        >
          <template v-slot:[`item.province`]="{ item }">
            {{ item.province | capitalize }}
          </template>
          <template v-slot:[`item.amountRequested`]="{ item }">
            {{ item.amountRequested | currency }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            {{ item.status | capitalize_first_letter }}
          </template>
          <template v-slot:[`item.loan_to_value`]="{ item }">
            {{ item.ltv }}%
          </template>
          <template v-slot:[`item.deal_summary_status`]="{ item }">
            {{
              item.deal_summaries.some((el) => el.status === "active")
                ? "Active"
                : "Inactive"
            }}
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn class="ml-2" @click="handle_cancel"> cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import API from "@/plugins/API";
import filt from "@/plugins/filters";

export default {
  name: "AddExistingDeal",

  props: {
    associated_deals: {
      type: Array,
    },

    type: {
      type: String,
    },
  },

  data: () => ({
    search: "",

    header_deals: [
      {
        text: "Public Name",
        align: "start",
        filterable: true,
        value: "public_name",
      },
      {
        text: "Internal Name",
        align: "start",
        filterable: true,
        value: "internal_name",
      },

      {
        text: "Province",
        align: "start",
        filterable: true,
        value: "province",
      },
      {
        text: "Status",
        align: "start",
        filterable: true,
        value: "status",
      },
      {
        text: "Deal Summary Status",
        align: "start",
        filterable: true,
        value: "deal_summary_status",
      },

      {
        text: "Stage",
        align: "start",
        filterable: true,
        value: "kbn_stg.stage_name",
      },
    ],

    deals: [],
  }),

  computed: {
    unassociated_deals() {
      if (this.deals) {
        let associatedIds = null;

        if (this.type === "associate_to_customer") {
          associatedIds = this.associated_deals.map(
            (int_cust) => int_cust.deal.id
          );
        }

        if (this.type === "associate_to_property") {
          associatedIds = this.associated_deals.map(
            (int_prop) => int_prop.deal.id
          );
        }

        return this.deals.filter(
          (deal) => !associatedIds.includes(deal.id) && deal.status === "In Progress"
        );
      } else {
        return [];
      }
    },
  },

  methods: {
    handle_cancel() {
      this.$emit("handle_cancel");
    },

    handle_selected_deal(value) {
      this.$emit("handle_selected_deal", value);
    },

    async get_deals() {
      try {
        const response = await API().get(
          `/api/internal-admin/getAllDeals?status=all`
        );

        console.log(response);

        this.deals = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },

  filters: {
    ...filt,
  },

  mounted() {
    this.get_deals();
  },
};
</script>
