<template>
  <v-form v-model="new_liability_is_valid">
    <v-card>
      <v-card-title>
        {{ adding_liability ? "Adding Liability" : "Updating Liability" }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-switch
              v-model="new_liability_data.single"
              :label="new_liability_data.single ? 'Joint' : 'Single'"
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-select
              v-model="new_liability_data.liability_type"
              :items="liability_type_options"
              label="Liability Type"
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_liability_data.balance"
              label="Balance"
              outlined
              hide-details="auto"
              @blur="
                new_liability_data.balance = formatCurrency(
                  new_liability_data.balance
                )
              "
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="new_liability_data.payment"
                  label="Payment"
                  outlined
                  hide-details="auto"
                  @blur="
                    new_liability_data.payment = formatCurrency(
                      new_liability_data.payment
                    )
                  "
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-menu
                  v-model="maturity_date_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="new_liability_data.maturity_date"
                      label="Maturity Date"
                      outlined
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                      :rules="rule.date"
                      hint="YYYY-MM-DD format"
                      append-icon="mdi-calendar-blank"
                    />
                  </template>
                  <v-date-picker
                    v-model="new_liability_data.maturity_date"
                    no-title
                    @input="maturity_date_menu = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <v-textarea
              v-model="new_liability_data.additional_description"
              label="Additional Description"
              outlined
              auto-grow
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          v-if="adding_liability"
          :disabled="!new_liability_is_valid"
          color="primary"
          @click="$emit('save_add', new_liability_data)"
        >
          save
        </v-btn>

        <v-btn
          v-if="updating_liability"
          :disabled="!new_liability_is_valid"
          color="primary"
          @click="$emit('save_update', new_liability_data)"
        >
          update
        </v-btn>

        <v-btn @click="$emit('close')"> cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  name: "AddLiability",

  props: {
    adding_liability: {
      type: Boolean,
    },

    updating_liability: {
      type: Boolean,
    },

    rule: {
      type: Object,
    },

    liability_data: {
      type: Object,
    },
  },

  data: () => ({
    maturity_date_menu: false,
    new_liability_is_valid: false,

    // liability options
    liability_type_options: [
      "Mortgage",
      "Credit Card",
      "Personal Loan",
      "Auto Loan",
      "Alimony",
      "Child Support",
      "Student Loan",
      "Wage Garnishment",
      "Unsecured Line of Credit",
      "Secured Line of Credit",
      "Income Tax",
      "Mortgage Rental Property",
      "Lease",
      "Auto Lease",
      "Rent",
      "Other",
    ],

    // initialize liability data
    new_liability_data: {
      liability_type: null,
      balance: null,
      payment: null,
      maturity_date: null,
      additional_description: null,
      archived: false,
      single: false,
    },
  }),

  methods: {
    formatCurrency(amount) {
      let currencyValue = 0;

      if (amount !== 0 && amount !== null && amount !== undefined) {
        // Remove commas and dollar signs
        let unformattedValue = amount.replace(/[$,]/g, "");

        // Parse the input value as a number
        currencyValue = parseInt(unformattedValue) || 0;

        // Format the number with commas and a dollar sign
        let formatted_amount = currencyValue
          .toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
          .replace(".00", "");

        return formatted_amount;
      } else {
        return 0;
      }
    },
  },

  mounted() {
    if (this.updating_liability) {
      this.new_liability_data = this.liability_data;
    }
  },
};
</script>
