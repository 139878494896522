<template>
  <v-form v-model="new_employment_is_valid">
    <v-card>
      <v-card-title>
        {{ adding_employment ? "Adding Employment" : "Updating Employment" }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="new_employment_data.employer_name"
              label="Employer Name"
              outlined
              hide-details="auto"
            />
          </v-col>
          <v-col>
            <v-switch
              label="Current Job"
              v-model="new_employment_data.current_job"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="new_employment_data.employment_type"
              :items="employment_type_options"
              label="Employment Type"
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_employment_data.employer_phone_number"
              label="Employer Phone Number"
              type="number"
              :rules="rule.phone"
              outlined
              hide-details="auto"
              @blur="
                new_employment_data.employer_phone_number === ''
                  ? (new_employment_data.employer_phone_number = null)
                  : (new_employment_data.employer_phone_number =
                      new_employment_data.employer_phone_number)
              "
            />
          </v-col>
        </v-row>

        <v-divider class="my-5" />

        <v-row>
          <v-col>
            <h5 class="grey--text">Employer Address:</h5>
          </v-col>
        </v-row>

        <Places @placeChanged="place_changed" />

        <v-row>
          <v-col>
            <v-text-field
              v-model="new_employment_data.address.line1"
              label="Address Line 1"
              outlined
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="new_employment_data.address.line2"
              label="Address Line 2"
              outlined
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="new_employment_data.address.street_number"
              label="Street Number"
              outlined
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_employment_data.address.street_name"
              label="Street Name"
              outlined
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-select
              v-model="new_employment_data.address.street_type"
              label="Street Type"
              :items="street_type_options"
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="new_employment_data.address.street_direction"
              label="Street Direction"
              :items="street_direction_options"
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_employment_data.address.unit"
              label="Unit"
              outlined
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_employment_data.address.postal_code"
              label="Postal Code"
              outlined
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="new_employment_data.address.city"
              label="City"
              outlined
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-select
              :items="province_options"
              v-model="new_employment_data.address.province"
              label="Province"
            />
          </v-col>
        </v-row>

        <v-divider class="my-5" />

        <v-row>
          <v-col>
            <v-switch
              label="Self Employed"
              v-model="new_employment_data.self_employed"
              @change="
                new_employment_data.self_employed
                  ? (new_employment_data.business_for_self = true)
                  : (new_employment_data.business_for_self = false)
              "
            />
          </v-col>

          <v-col v-if="new_employment_data.self_employed">
            <v-select
              :items="operating_as_options"
              v-model="new_employment_data.operating_as"
              label="Operating as"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-if="new_employment_data.self_employed"
              v-model="new_employment_data.gross_revenue"
              label="Gross Revenue"
              outlined
              hide-details="auto"
              @blur="
                new_employment_data.gross_revenue = formatCurrency(
                  new_employment_data.gross_revenue
                )
              "
            />
          </v-col>
        </v-row>

        <v-row v-if="new_employment_data.business_for_self" class="mt-2">
          <!-- Business for Self Notes -->
          <v-col>
            <p v-if="new_employment_data.business_self_notes.length > 0">
              Business for Self Notes:
            </p>

            <v-data-table
              v-if="new_employment_data.business_self_notes.length > 0"
              style="background-color: #111"
              v-model="selected_business_self_notes"
              :headers="header_note"
              item-key="order"
              @click:row="handle_updating_business_self_note($event)"
              :items="new_employment_data.business_self_notes"
              :items-per-page="5"
              show-select
            />

            <v-btn
              class="mt-2"
              color="error"
              v-if="selected_business_self_notes.length > 0"
              @click="handle_delete_business_self_note"
            >
              remove
            </v-btn>

            <br />

            <v-btn
              class="mt-2"
              v-if="!adding_business_self_note && !updating_business_self_note"
              @click="adding_business_self_note = true"
            >
              add note
            </v-btn>

            <v-form v-model="valid_business_self_note">
              <v-card
                outlined
                class="mt-5"
                v-if="adding_business_self_note || updating_business_self_note"
              >
                <v-card-title class="text-body-1">
                  {{ adding_business_self_note ? "Adding" : "Updating" }}
                  Business for Self Note
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="new_business_self_note.note"
                        label="Note"
                        outlined
                        auto-grow
                        hide-details="auto"
                        :rules="rule.required"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-btn
                    color="primary"
                    @click="
                      adding_business_self_note
                        ? handle_save_business_self_note()
                        : handle_save_update_business_self_note()
                    "
                    :disabled="!valid_business_self_note"
                  >
                    save
                  </v-btn>

                  <v-btn
                    @click="
                      adding_business_self_note = false;
                      updating_business_self_note = false;
                    "
                  >
                    cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>

        <v-divider v-if="new_employment_data.business_for_self" class="my-5" />

        <v-row>
          <v-col>
            <v-text-field
              v-model="new_employment_data.company_type"
              label="Company Type"
              outlined
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_employment_data.job_title"
              label="Job Title"
              outlined
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-select
              v-model="new_employment_data.occupation"
              :items="occupation_options"
              label="Occupation"
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="new_employment_data.income_type"
              :items="income_type_options"
              label="Income Type"
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-select
              v-model="new_employment_data.industry_sector"
              :items="industry_sector_options"
              label="Industry Sector"
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="new_employment_data.income_period"
              :items="income_period_options"
              label="Income Period"
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_employment_data.income_amount"
              label="Income Amount"
              outlined
              hide-details="auto"
              @blur="
                new_employment_data.income_amount = formatCurrency(
                  new_employment_data.income_amount
                )
              "
            />
          </v-col>
        </v-row>

        <v-divider class="my-5" />

        <v-row class="mt-2">
          <v-col cols="3">
            <v-switch
              v-model="new_employment_data.variable_income"
              label="Variable Income"
              hide-details="auto"
            />
          </v-col>

          <!-- Variable Income Notes -->
          <v-col v-if="new_employment_data.variable_income">
            <p v-if="new_employment_data.variable_income_notes.length > 0">
              Variable Income Notes:
            </p>

            <v-data-table
              v-if="new_employment_data.variable_income_notes.length > 0"
              style="background-color: #111"
              v-model="selected_variable_income_notes"
              :headers="header_note"
              item-key="order"
              @click:row="handle_updating_variable_income_note($event)"
              :items="new_employment_data.variable_income_notes"
              :items-per-page="5"
              show-select
            />

            <v-btn
              class="mt-2"
              color="error"
              v-if="selected_variable_income_notes.length > 0"
              @click="handle_delete_variable_income_note"
            >
              remove
            </v-btn>

            <br />

            <v-btn
              class="mt-2"
              v-if="
                !adding_variable_income_note && !updating_variable_income_note
              "
              @click="adding_variable_income_note = true"
            >
              add note
            </v-btn>

            <v-form v-model="valid_variable_income_note">
              <v-card
                outlined
                class="mt-5"
                v-if="
                  adding_variable_income_note || updating_variable_income_note
                "
              >
                <v-card-title class="text-body-1">
                  {{ adding_variable_income_note ? "Adding" : "Updating" }}
                  Variable Income Note
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="new_variable_income_note.note"
                        label="Note"
                        outlined
                        auto-grow
                        hide-details="auto"
                        :rules="rule.required"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-btn
                    color="primary"
                    @click="
                      adding_variable_income_note
                        ? handle_save_variable_income_note()
                        : handle_save_update_variable_income_note()
                    "
                    :disabled="!valid_variable_income_note"
                  >
                    save
                  </v-btn>

                  <v-btn
                    @click="
                      adding_variable_income_note = false;
                      updating_variable_income_note = false;
                    "
                  >
                    cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>

        <v-divider v-if="new_employment_data.variable_income" class="my-5" />

        <v-row>
          <v-col>
            <h5 class="grey--text">Time at this Company:</h5>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="new_employment_data.time_at_this_company_years"
              label="Years at this Company"
              outlined
              hide-details="auto"
              :rules="rule.number"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_employment_data.time_at_this_company_months"
              label="Months at this Company"
              outlined
              hide-details="auto"
              :rules="rule.month"
            />
          </v-col>
        </v-row>

        <v-divider class="my-5" />

        <v-row>
          <v-col>
            <h5 class="grey--text">Time at this Industry:</h5>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="new_employment_data.time_at_this_industry_years"
              label="Years in this Industry"
              outlined
              hide-details="auto"
              :rules="rule.number"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_employment_data.time_at_this_industry_months"
              label="Months in this Industry"
              outlined
              hide-details="auto"
              :rules="rule.month"
            />
          </v-col>
        </v-row>

        <v-divider class="my-5" />

        <v-row>
          <v-col>
            <v-textarea
              v-model="new_employment_data.note"
              label="Add Notes"
              outlined
              auto-grow
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          v-if="adding_employment"
          :disabled="!new_employment_is_valid"
          color="primary"
          @click="$emit('save_add', new_employment_data)"
        >
          save
        </v-btn>

        <v-btn
          v-if="updating_employment"
          :disabled="!new_employment_is_valid"
          color="primary"
          @click="$emit('save_update', new_employment_data)"
        >
          update
        </v-btn>

        <v-btn @click="$emit('close')"> cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import Places from "@/components/google_maps/Places";

export default {
  name: "AddEmployment",

  props: {
    adding_employment: {
      type: Boolean,
    },

    updating_employment: {
      type: Boolean,
    },

    rule: {
      type: Object,
    },

    employment_data: {
      type: Object,
    },

    main_employment_data: {
      type: Array,
    },

    viewed_as: {
      type: String,
    },
  },

  components: {
    Places,
  },

  data: () => ({
    new_employment_is_valid: false,

    // -- business self note
    adding_business_self_note: false,
    updating_business_self_note: false,
    selected_business_self_notes: [],
    new_business_self_note: {
      note: null,
    },
    valid_business_self_note: false,

    // -- variable income note
    adding_variable_income_note: false,
    updating_variable_income_note: false,
    selected_variable_income_notes: [],
    new_variable_income_note: {
      note: null,
    },
    valid_variable_income_note: false,

    // employment options
    employment_type_options: ["Full Time", "Part Time", "Seasonal"],

    street_direction_options: ["N", "S", "E", "W", "NE", "NW", "SE", "SW"],

    street_type_options: [
      "Abbey",
      "Acres",
      "Allée",
      "Alley",
      "Autoroute",
      "Avenue",
      "Bay",
      "Beach",
      "Bend",
      "Boulevard",
      "By-Pass",
      "Byway",
      "Campus",
      "Cape",
      "Carré",
      "Carrefour",
      "Centre",
      "Cercle",
      "Chase",
      "Chemin",
      "Circle",
      "Circuit",
      "Close",
      "Common",
      "Concession",
      "Corners",
      "Côte",
      "Cour",
      "Cours",
      "Court",
      "Cove",
      "Crescent",
      "Croissant",
      "Crossing",
      "Cul-de-sac",
      "Dale",
      "Dell",
      "Diversion",
      "Downs",
      "Drive",
      "Éhangeur",
      "End",
      "Esplande",
      "Estates",
      "Expressway",
      "Extension",
      "Farm",
      "Field",
      "Forest",
      "Freeway",
      "Front",
      "Gardens",
      "Gate",
      "Glen",
      "Green",
      "Grounds",
      "Grove",
      "Harbour",
      "Heath",
      "Heights",
      "Highlands",
      "Highway",
      "Hill",
      "Hollow",
      "Île",
      "Impasse",
      "Inlet",
      "Island",
      "Key",
      "knoll",
      "Landing",
      "Lane",
      "Limits",
      "Line",
      "Link",
      "Lookout",
      "Loop",
      "Mall",
      "Manor",
      "Maze",
      "Meadow",
      "Mews",
      "Montée",
      "Moor",
      "Mount",
      "Mountain",
      "Orchard",
      "Parade",
      "Parc",
      "Park",
      "Parkway",
      "Passage",
      "Path",
      "Pathway",
      "Pines",
      "Place",
      "Plateau",
      "Plaza",
      "Point",
      "Pointe",
      "Port",
      "Private",
      "Promenade",
      "Quai",
      "Quay",
      "Ramp",
      "Rang",
      "Range",
      "Ridge",
      "Rise",
      "Road",
      "Rond-point",
      "Route",
      "Row",
      "Rue",
      "Ruelle",
      "Run",
      "Sentier",
      "Square",
      "Street",
      "Subdivision",
      "Terrance",
      "Terrasse",
      "Thicket",
      "Towers",
      "Townline",
      "Trail",
      "Turnabout",
      "Vale",
      "Via",
      "View",
      "Village",
      "Villas",
      "Vista",
      "Voie",
      "Walk",
      "Way",
      "Wharf",
      "Wood",
      "Wynd",
    ],

    operating_as_options: ["Sole Proprietorship", "Partnership", "Corporation"],

    occupation_options: [
      "Self-Employed",
      "Management",
      "Clerical",
      "Labour / Tradesperson",
      "Retired",
      "Professional",
      "Other",
    ],

    income_type_options: [
      "Salary",
      "Hourly",
      "Hourly + Commissions",
      "Commissions",
      "Self-Employed",
      "Other Employment Income",
    ],

    industry_sector_options: [
      "Construction",
      "Government",
      "Health",
      "Education",
      "High-Tech",
      "Retail Sales",
      "Leisure / Entertainment",
      "Banking / Finance",
      "Transportation",
      "Services",
      "Manufacturing",
      "Farming / Natural Resources",
      "Varies",
      "Other",
    ],

    income_period_options: [
      "Annual",
      "Semi-Annual",
      "Quarterly",
      "Monthly",
      "Semi-Monthly",
      "Bi-Weekly",
      "Weekly",
    ],

    province_options: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Nova Scotia",
      "Northwest Territories",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon",
    ],

    // headers
    header_note: [{ text: "Note", value: "note" }],

    // initialize employment data
    new_employment_data: {
      employer_name: null,
      current_job: false,
      employment_type: null,
      employer_phone_number: null,
      business_for_self: false,
      variable_income: false,
      self_employed: false,
      operating_as: null,
      gross_revenue: null,
      company_type: null,
      job_title: null,
      occupation: null,
      income_type: null,
      industry_sector: null,
      income_period: null,
      income_amount: null,
      time_at_this_company_years: null,
      time_at_this_company_months: null,
      time_at_this_industry_years: null,
      time_at_this_industry_months: null,
      archived: false,
      note: null,
      business_self_notes: [],
      variable_income_notes: [],

      address: {
        line1: null,
        line2: null,
        street_number: null,
        street_name: null,
        street_type: null,
        street_direction: null,
        unit: null,
        postal_code: null,
        city: null,
        province: null,
        archived: false,
      },
    },
  }),

  methods: {
    // -- business self note
    handle_save_business_self_note() {
      console.log(this.new_employment_data.business_self_notes.length);
      let new_order = 0;

      if (this.new_employment_data.business_self_notes.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.new_employment_data.business_self_notes.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      this.new_business_self_note.order = new_order;

      this.new_employment_data.business_self_notes.push(
        this.new_business_self_note
      );
      this.adding_business_self_note = false;
      this.new_business_self_note = { note: "" };
    },

    handle_updating_business_self_note(data) {
      if (this.adding_business_self_note) {
        alert("Please save the note you are adding before updating it.");
        return;
      } else {
        this.updating_business_self_note = true;
        this.new_business_self_note = data;
      }
    },

    handle_save_update_business_self_note() {
      const index = this.new_employment_data.business_self_notes.findIndex(
        (item) => item.order === this.new_business_self_note.order
      );

      this.new_employment_data.business_self_notes[index].note =
        this.new_business_self_note.note;
      this.updating_business_self_note = false;
      this.new_business_self_note = { note: "" };
    },

    handle_delete_business_self_note() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_business_self_notes.forEach((el) => {
          const index = this.new_employment_data.business_self_notes.findIndex(
            (item) => item.order === el.order
          );

          this.new_employment_data.business_self_notes.splice(index, 1);
        });

        this.selected_business_self_notes = [];
      } else {
        console.log("deleting business self note was canceled.");
        this.selected_business_self_notes = [];
      }
    },

    // -- variable income note
    handle_save_variable_income_note() {
      let new_order = 0;

      if (this.new_employment_data.variable_income_notes.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.new_employment_data.variable_income_notes.map(
            (item) => item.order
          )
        );
        new_order = highest_order + 1;
      }

      this.new_variable_income_note.order = new_order;

      this.new_employment_data.variable_income_notes.push(
        this.new_variable_income_note
      );
      this.adding_variable_income_note = false;
      this.new_variable_income_note = { note: "" };
    },

    handle_updating_variable_income_note(data) {
      if (this.adding_variable_income_note) {
        alert("Please save the note you are adding before updating it.");
        return;
      } else {
        this.updating_variable_income_note = true;
        this.new_variable_income_note = data;
      }
    },

    handle_save_update_variable_income_note() {
      const index = this.new_employment_data.variable_income_notes.findIndex(
        (item) => item.order === this.new_variable_income_note.order
      );

      this.new_employment_data.variable_income_notes[index].note =
        this.new_variable_income_note.note;
      this.updating_variable_income_note = false;
      this.new_variable_income_note = { note: "" };
    },

    handle_delete_variable_income_note() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_variable_income_notes.forEach((el) => {
          const index =
            this.new_employment_data.variable_income_notes.findIndex(
              (item) => item.order === el.order
            );

          this.new_employment_data.variable_income_notes.splice(index, 1);
        });

        this.selected_variable_income_notes = [];
      } else {
        console.log("deleting variable income note was canceled.");
        this.selected_variable_income_notes = [];
      }
    },

    formatCurrency(amount) {
      let currencyValue = 0;

      if (amount !== 0) {
        // Remove commas and dollar signs
        let unformattedValue = amount.replace(/[$,]/g, "");

        // Parse the input value as a number
        currencyValue = parseInt(unformattedValue) || 0;

        // Format the number with commas and a dollar sign
        let formatted_amount = currencyValue
          .toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
          .replace(".00", "");

        return formatted_amount;
      } else {
        return 0;
      }
    },

    place_changed(place) {
      const addressComponents = place.address_components;

      const getAddressComponent = (type) => {
        const component = addressComponents.find((component) =>
          component.types.includes(type)
        );
        return component ? component.long_name : "";
      };

      // Assign basic address components
      this.new_employment_data.address.street_number =
        getAddressComponent("street_number");
      let streetName = getAddressComponent("route");
      this.new_employment_data.address.city = getAddressComponent("locality");
      this.new_employment_data.address.province = getAddressComponent(
        "administrative_area_level_1"
      );
      this.new_employment_data.address.postal_code =
        getAddressComponent("postal_code");
      this.new_employment_data.address.unit = getAddressComponent("subpremise");

      // Define street direction options in long text
      const streetDirectionOptions = [
        "North",
        "South",
        "East",
        "West",
        "Northeast",
        "Northwest",
        "Southeast",
        "Southwest",
      ];

      // Function to extract street direction and type
      const extractStreetDetails = (streetName) => {
        let direction = "";
        let type = "";

        // Check for direction at the start of the street name
        const directionPattern = new RegExp(
          `\\b(${streetDirectionOptions.join("|")})$`,
          "i"
        );
        const directionMatch = streetName.match(directionPattern);
        if (directionMatch) {
          direction = directionMatch[0];
          streetName = streetName.replace(directionPattern, "").trim();
        }

        // Check for type at the end of the street name
        const typePattern = new RegExp(
          `\\b(${this.street_type_options.join("|")})$`,
          "i"
        );

        const typeMatch = streetName.match(typePattern);
        if (typeMatch) {
          type = typeMatch[0];
          streetName = streetName.replace(typePattern, "").trim();
        }

        return { streetName, direction, type };
      };

      // Extract and assign street details
      const {
        streetName: finalStreetName,
        direction,
        type,
      } = extractStreetDetails(streetName);

      this.new_employment_data.address.street_name = finalStreetName;
      this.new_employment_data.address.street_type = type;

      // Assign direction from long text to short text
      const directionMap = {
        North: "N",
        South: "S",
        East: "E",
        West: "W",
        Northeast: "NE",
        Northwest: "NW",
        Southeast: "SE",
        Southwest: "SW",
      };

      this.new_employment_data.address.street_direction =
        directionMap[direction] || "";

      // Construct address_line1 and address_line2
      const addressLine1Components = [
        this.new_employment_data.address.street_number,
        this.new_employment_data.address.street_name,
        this.new_employment_data.address.street_direction,
        this.new_employment_data.address.street_type,
      ]
        .filter(Boolean)
        .join(" ");

      this.new_employment_data.address.line1 = addressLine1Components;

      this.new_employment_data.address.line2 = this.new_employment_data.address
        .unit
        ? `${this.new_employment_data.address.unit}`
        : "";
    },
  },

  mounted() {
    if (this.updating_employment) {
      for (
        let i = 0;
        i < this.employment_data.business_self_notes.length;
        i++
      ) {
        this.employment_data.business_self_notes[i].order = i + 1;
      }

      for (
        let i = 0;
        i < this.employment_data.variable_income_notes.length;
        i++
      ) {
        this.employment_data.variable_income_notes[i].order = i + 1;
      }

      this.new_employment_data = this.employment_data;
    }
  },
};
</script>
