<template>
  <v-form v-model="new_relationship_is_valid">
    <v-card>
      <v-card-title class="text-body-2">
        {{ adding_relationship ? "Add" : "Update" }} Relationship
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-row>
          <v-col v-show="!updating_relationship">
            <v-autocomplete
              v-model="new_relationship_data.cust_2"
              :items="customerItems"
              item-text="name"
              item-value="value"
              label="Customer Name"
              :rules="rule.required"
              :menu-props="{ maxHeight: '200px' }"
            />
          </v-col>

          <v-col>
            <v-select
              v-model="new_relationship_data.type"
              :items="relationship_options"
              label="Relationship"
              :rules="rule.required"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          :disabled="!new_relationship_is_valid"
          color="primary"
          @click="$emit('save_add', new_relationship_data)"
        >
          save
        </v-btn>

        <v-btn @click="$emit('close')"> cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  name: "AddRelationship",

  props: {
    rule: {
      type: Object,
    },

    adding_relationship: {
      type: Boolean,
    },

    updating_relationship: {
      type: Boolean,
    },

    relationship_data: {
      type: Array,
    },

    existing_relationship_data: {
      type: Object,
    },

    customers: {
      type: Array,
    },
  },

  data: () => ({
    new_relationship_is_valid: false,

    // relationship options
    relationship_options: [
      "Spouse",
      "Common Law",
      "Related Family Member",
      "Parent",
      "Sibling",
      "Child",
      "Grandchild",
      "Grandparent",
      "Other",
    ],

    // initialize relationship data
    new_relationship_data: {
      type: null,
      cust_2: null,
    },
  }),

  computed: {
    customerItems() {
      let relationshipNames = this.relationship_data.map(
        (data) => `${data.cust_2.first_name} ${data.cust_2.last_name}`
      );

      return this.customers
        .filter(
          (customer) =>
            !relationshipNames.includes(
              `${customer.first_name} ${customer.last_name}`
            )
        )
        .map((customer, index) => ({
          name: customer.email
            ? `${customer.first_name} ${customer.last_name} - ${customer.email}`
            : `${customer.first_name} ${customer.last_name}`,
          value: customer,
        }));
    },
  },

  mounted() {
    // console.log("relationship data: ", this.relationship_data);
    // console.log("existing: ", this.existing_relationship_data);
    if (this.updating_relationship) {
      this.new_relationship_data = this.existing_relationship_data;
    }
  },
};
</script>
