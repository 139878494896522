<template>
  <v-form ref="form" @submit.prevent="submitForm">
    <v-row>
      <v-col cols="12" class="d-flex align-start">
        <p class="grey--text">
          Add a link to a deal. This could be a link to any other resource that
          you think would be helpful.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea v-model="url" label="URL" rows="1" outlined />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea v-model="description" label="Description" outlined />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn color="teal" type="submit" text>Submit</v-btn>
        <v-btn @click="$emit('close')" text>cancel</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    id: null,
    url: "",
    description: "Sharepoint",
  }),

  methods: {
    submitForm() {
      this.$emit("add-link", {
        id: this.id,
        url: this.url,
        description: this.description,
      });

      this.resetForm();
    },

    setForm(data) {
      this.id = data.id;
      this.url = data.url;
      this.description = data.description;
    },

    resetForm() {
      this.id = null;
      this.url = "";
      this.description = "Sharepoint";
    },
  },
};
</script>
