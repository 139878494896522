<template>
  <v-container>
    <v-alert
      v-model="error"
      transition="fade-transition"
      type="error"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        {{ errorMessage }}
      </div>
    </v-alert>

    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Please be patient, this may take a while.
        </h3>
      </v-col>
    </v-row>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="7">
        <v-expansion-panels v-model="panel" multiple>
          <!-- customer information -->
          <v-expansion-panel class="bordered mb-5">
            <v-expansion-panel-header>
              <div>
                Customer Information <v-icon class="ml-2">mdi-account</v-icon>
                <v-btn
                  class="ml-2"
                  title="Edit Customer Information"
                  icon
                  @click="
                    $router.push(
                      `/dashboard/admin/customer/update?customer_id=${id}`
                    )
                  "
                >
                  <v-icon> mdi-pencil-outline </v-icon>
                </v-btn>
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td :width="250">First Name</td>
                      <td>{{ customer.first_name }}</td>
                    </tr>

                    <tr>
                      <td>Middle Name</td>
                      <td>{{ customer.middle_name }}</td>
                    </tr>

                    <tr>
                      <td>Last Name</td>
                      <td>{{ customer.last_name }}</td>
                    </tr>

                    <tr>
                      <td>Email</td>
                      <td>
                        <div
                          v-for="contact_info in customer.contact_infos.filter(
                            (el) => el.type === 'Email'
                          )"
                          :key="contact_info.id"
                        >
                          {{ contact_info.email }}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Cellphone Number</td>
                      <td>
                        <div
                          v-for="contact_info in customer.contact_infos.filter(
                            (el) => el.type === 'Cell Phone Number'
                          )"
                          :key="contact_info.id"
                        >
                          {{ contact_info.number }}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Home Phone Number</td>
                      <td>
                        <div
                          v-for="contact_info in customer.contact_infos.filter(
                            (el) => el.type === 'Home Phone Number'
                          )"
                          :key="contact_info.id"
                        >
                          {{ contact_info.number }}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Work Phone Number</td>
                      <td>
                        <div
                          v-for="contact_info in customer.contact_infos.filter(
                            (el) => el.type === 'Work Phone Number'
                          )"
                          :key="contact_info.id"
                        >
                          {{ contact_info.number }}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Birthday</td>
                      <td>{{ customer.birthday | formatDate }}</td>
                    </tr>

                    <tr>
                      <td>Social Insurance Number</td>
                      <td>{{ customer.social_insurance_number }}</td>
                    </tr>

                    <tr>
                      <td>Language Preference</td>
                      <td>{{ customer.language_preference }}</td>
                    </tr>

                    <tr>
                      <td>Marital Status</td>
                      <td>{{ customer.marital_status }}</td>
                    </tr>

                    <tr>
                      <td>Marital Status Note</td>
                      <td>{{ customer.marital_status_note }}</td>
                    </tr>

                    <tr>
                      <td>Dependents</td>
                      <td>{{ customer.dependents }}</td>
                    </tr>

                    <tr>
                      <td>First Home</td>
                      <td>{{ customer.first_home | yes_no }}</td>
                    </tr>

                    <tr>
                      <td>Current Residential Status</td>
                      <td>{{ customer.current_residential_status }}</td>
                    </tr>

                    <tr>
                      <td>First Home</td>
                      <td>
                        {{ customer.current_residential_status | yes_no }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <!-- address history -->

              <v-expansion-panel class="mt-2 bordered">
                <v-expansion-panel-header> Links </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <p v-if="customer.links.length > 0" class="grey--text">
                    Click on the link to open it in a new tab.
                  </p>

                  <v-list class="pa-0">
                    <v-list-item
                      v-for="link in customer.links"
                      :key="link.id"
                      class="d-flex align-center"
                      link
                    >
                      <v-list-item-content>
                        <a
                          :href="
                            link.url.startsWith('http://') ||
                            link.url.startsWith('https://')
                              ? link.url
                              : 'http://' + link.url
                          "
                          target="_blank"
                        >
                          {{ link.description }}
                        </a>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="d-flex align-center">
                          <v-btn
                            icon
                            @click="edit_link(link)"
                            color="teal"
                            title="Edit Link"
                          >
                            <v-icon>mdi-pencil-outline</v-icon>
                          </v-btn>

                          <v-btn
                            icon
                            @click="delete_link(link.id)"
                            color="red"
                            title="Delete Link"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>

                  <div class="d-flex justify-end mt-5">
                    <v-btn @click="dialog_link = true" color="teal" text>
                      + Add New Link
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- add new link dialog -->
              <v-dialog v-model="dialog_link" max-width="500px" persistent>
                <v-toolbar color="orange darken-3">
                  <v-toolbar-title flat>
                    {{ is_edit_link ? "Edit" : "Add New" }} Link
                  </v-toolbar-title>
                  <v-spacer />
                  <v-btn
                    icon
                    @click="
                      dialog_link = false;
                      is_edit_link = false;
                      $refs.link_form.resetForm();
                    "
                    title="Click to close"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card>
                  <v-card-text>
                    <AddLinkForm
                      class="pt-10"
                      :is_edit_link="is_edit_link"
                      ref="link_form"
                      @add-link="submit_link"
                      @close="
                        dialog_link = false;
                        is_edit_link = false;
                        $refs.link_form.resetForm();
                      "
                    />
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-expansion-panel class="bordered mt-5">
                <v-expansion-panel-header>
                  Address History
                  <v-spacer />
                  <span
                    class="d-flex justify-end"
                    :style="
                      address_time_at_residence(customer.addresses).year > 2
                        ? 'color: green'
                        : 'color: orange'
                    "
                  >
                    Time at residence:
                    {{ address_time_at_residence(customer.addresses).text }}
                  </span>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div class="my-5">
                    <v-data-table
                      class="mb-2"
                      :headers="header_address"
                      :items="customer.addresses.filter((el) => !el.archived)"
                      :items-per-page="5"
                      item-key="id"
                      @click:row="handle_updating_address($event)"
                      v-model="selected_address"
                      show-select
                      dense
                    >
                      <template v-slot:[`item.full_address`]="{ item }">
                        {{ item.street_number }},
                        {{ item.street_name }}
                        {{ item.street_type }}, {{ item.street_direction }},
                        {{ item.unit }}, {{ item.postal_code }},
                        {{ item.city }},
                        {{ item.province }}
                      </template>
                    </v-data-table>
                    <v-btn
                      v-if="selected_address.length > 0"
                      @click="handle_delete_address()"
                      color="error"
                    >
                      delete
                    </v-btn>
                  </div>

                  <v-btn
                    v-if="!adding_address && !updating_address"
                    @click="adding_address = true"
                  >
                    add address
                  </v-btn>

                  <Address
                    v-if="adding_address || updating_address"
                    :adding_address="adding_address"
                    :updating_address="updating_address"
                    :address_data="new_address_data"
                    :main_address_data="address_data"
                    :rule="rule"
                    @save_add="handle_save_address"
                    @save_update="handle_save_update_address"
                    @close="
                      adding_address = false;
                      updating_address = false;
                    "
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- employments -->
              <v-expansion-panel class="bordered mt-5">
                <v-expansion-panel-header>
                  Employment

                  <v-spacer />

                  <span
                    class="d-flex justify-end"
                    :style="
                      time_at_job(customer.cust_emps).year > 2
                        ? 'color: green'
                        : 'color: orange'
                    "
                  >
                    Total years of employment:
                    {{ time_at_job(customer.cust_emps).text }}
                  </span>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div class="my-5">
                    <v-data-table
                      class="mb-2"
                      :headers="header_employment"
                      :items="customer.cust_emps.filter((el) => !el.archived)"
                      :items-per-page="5"
                      item-key="id"
                      @click:row="handle_updating_employment($event)"
                      v-model="selected_employment"
                      show-select
                      dense
                    />

                    <v-btn
                      v-if="selected_employment.length > 0"
                      @click="handle_delete_employment()"
                      color="error"
                    >
                      delete
                    </v-btn>
                  </div>

                  <v-btn
                    v-if="!adding_employment && !updating_employment"
                    @click="adding_employment = true"
                  >
                    add employment
                  </v-btn>

                  <Employment
                    v-if="adding_employment || updating_employment"
                    :adding_employment="adding_employment"
                    :updating_employment="updating_employment"
                    :employment_data="new_employment_data"
                    :main_employment_data="employment_data"
                    :rule="rule"
                    @save_add="handle_save_employment"
                    @save_update="handle_save_update_employment"
                    @close="
                      adding_employment = false;
                      updating_employment = false;
                    "
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- properties -->
              <v-expansion-panel class="bordered mt-5">
                <v-expansion-panel-header>
                  Properties
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div>
                    <v-data-table
                      class="mb-2"
                      v-model="selected_property"
                      :headers="header_property"
                      :items="customer.props.filter((el) => !el.archived)"
                      :items-per-page="5"
                      item-key="id"
                      show-select
                      @click:row="handle_click_property_row($event)"
                    >
                      <template v-slot:item.address="{ item }">
                        <span>
                          {{ item.address.street_number + "," }}
                          {{ item.address.street_name + "," }}
                          {{ item.address.street_type + "," }}
                          {{ item.address.street_direction + "," }}
                          {{ item.address.unit + "," }}
                          {{ item.address.postal_code + "," }}
                          {{ item.address.city + "," }}
                          {{ item.address.province }}
                        </span>
                      </template>

                      <template v-slot:item.purchase_price="{ item }">
                        {{ item.purchase_price | currency }}
                      </template>

                      <template v-slot:item.purchase_date="{ item }">
                        {{ item.purchase_date | formatDate }}
                      </template>

                      <template v-slot:item.rental_property="{ item }">
                        {{ item.rental_property | yes_no }}
                      </template>
                    </v-data-table>

                    <v-btn
                      v-if="selected_property.length > 0"
                      @click="handle_unassociate_property()"
                      color="error"
                      class="mt-2"
                    >
                      unassociate
                    </v-btn>
                  </div>

                  <v-btn
                    class="mt-5"
                    @click="
                      $router.push(
                        `/dashboard/admin/property/create_from_customer?customer_id=${id}`
                      )
                    "
                  >
                    add property
                  </v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- liabilities -->
              <v-expansion-panel class="bordered mt-5">
                <v-expansion-panel-header>
                  Liabilities
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div class="my-5">
                    <v-data-table
                      class="mb-2"
                      :headers="header_liability"
                      :items="customer.cust_liabs.filter((el) => !el.archived)"
                      :items-per-page="5"
                      item-key="id"
                      v-model="selected_liability"
                      @click:row="handle_updating_liability($event)"
                      show-select
                      dense
                    />

                    <v-btn
                      v-if="selected_liability.length > 0"
                      @click="handle_delete_liability()"
                      color="error"
                    >
                      delete
                    </v-btn>
                  </div>

                  <v-btn
                    v-if="!adding_liability && !updating_liability"
                    @click="adding_liability = true"
                  >
                    add liability
                  </v-btn>

                  <Liability
                    v-if="adding_liability || updating_liability"
                    :adding_liability="adding_liability"
                    :updating_liability="updating_liability"
                    :liability_data="new_liability_data"
                    :rule="rule"
                    @save_add="handle_save_liability"
                    @save_update="handle_save_update_liability"
                    @close="
                      adding_liability = false;
                      updating_liability = false;
                    "
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- associated customer -->
          <v-expansion-panel class="bordered mb-5">
            <v-expansion-panel-header>
              <div>
                Associated Customers
                <v-icon class="ml-2">mdi-account-multiple</v-icon>
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-data-table
                :headers="header_relationship"
                :items="customer.cust_rltns_1"
                :items-per-page="5"
                @click:row="handle_click_associated_customer($event)"
                dense
              >
                <template v-slot:item.name="{ item }">
                  <span>
                    {{ item.cust_2.last_name }},
                    {{ item.cust_2.first_name }}
                    {{ item.cust_2.middle_name }}
                  </span>
                </template>
              </v-data-table>

              <div class="mt-5">
                <v-btn
                  v-if="!associating_customer"
                  @click="associating_customer = true"
                >
                  add relationship
                </v-btn>
              </div>

              <div class="mt-5">
                <Relationship
                  v-if="associating_customer"
                  :relationship_data="customer.cust_rltns_1"
                  :customers="customers.filter((el) => el.id !== customer.id)"
                  :rule="rule"
                  @save_add="handle_save_relationship"
                  @close="associating_customer = false"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- deals -->
          <v-expansion-panel class="bordered mb-5">
            <v-expansion-panel-header>
              Associated Deals
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-text-field
                v-model="search_deal"
                append-icon="mdi-magnify"
                label="Search"
                class="mb-2"
                single-line
                hide-details
              />

              <v-data-table
                @click:row="handle_click_deal"
                v-model="selected_deal"
                :headers="header_deals"
                :search="search_deal"
                :items="customer.int_custs"
                :items-per-page="-1"
                item-key="id"
                show-select
              >
                <template v-slot:[`item.province`]="{ item }">
                  {{ item.province | capitalize }}
                </template>
                <template v-slot:[`item.amountRequested`]="{ item }">
                  {{ item.amountRequested | currency }}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  {{ item.status | capitalize_first_letter }}
                </template>
                <template v-slot:[`item.loan_to_value`]="{ item }">
                  {{ item.ltv }}%
                </template>
                <template v-slot:[`item.deal_summary_status`]="{ item }">
                  {{
                    item.deal_summaries.some((el) => el.status === "active")
                      ? "Active"
                      : "Inactive"
                  }}
                </template>
              </v-data-table>

              <v-btn
                v-if="selected_deal.length > 0"
                color="red"
                class="mt-5"
                @click="handle_unassociate_deals"
              >
                remove
              </v-btn>

              <div class="mt-5">
                <v-btn
                  v-if="!adding_existing_deal"
                  @click="adding_existing_deal = true"
                >
                  Add Exisitng Deal
                </v-btn>
              </div>

              <AddExistingDeal
                type="associate_to_customer"
                :associated_deals="customer.int_custs"
                v-if="adding_existing_deal"
                @handle_selected_deal="handle_selected_deal"
                @handle_cancel="adding_existing_deal = false"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-btn
          @click="$router.push(`/dashboard/admin/customer/list-all-customers`)"
        >
          cancel
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import filt from "@/plugins/filters";
import Address from "@/components/customer/AddAddress.vue";
import Employment from "@/components/customer/AddEmployment.vue";
import Liability from "@/components/customer/AddLiability.vue";
import Relationship from "@/components/customer/AddRelationship.vue";
import AddExistingDeal from "@/components/customer/AddExistingDeal.vue";
import AddLinkForm from "@/components/deal/Add_Link_Form.vue";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  components: {
    Address,
    Employment,
    Liability,
    Relationship,
    AddExistingDeal,
    AddLinkForm,
  },

  data() {
    return {
      loading: true,
      error: null,
      errorMessage: "",
      search_deal: "",

      associating_customer: false,
      adding_existing_deal: false,

      panel: [0],

      customer: {},

      customers: [],

      // for links
      dialog_link: false,
      is_edit_link: false,

      // for address
      adding_address: false,
      updating_address: false,
      selected_address: [],
      selected_archived_address: [],

      // for employment
      adding_employment: false,
      updating_employment: false,
      selected_employment: [],
      selected_archived_employment: [],

      // for properties
      selected_property: [],

      // for liabilities
      adding_liability: false,
      updating_liability: false,
      selected_liability: [],
      selected_archived_liability: [],

      // for deals
      selected_deal: [],

      // applicant address data
      new_address_data: {},
      address_data: [],

      // applicant employment data
      new_employment_data: {},
      employment_data: [],

      // applicant liability data
      new_liability_data: {},
      liability_data: [],

      // headers
      header_address: [
        {
          text: "Full Address (Street #, Name, Direction, Unit #, Postal, City, Province)",
          value: "full_address",
        },
        { text: "Years", value: "years" },
        { text: "Months", value: "months" },
        { text: "Present", value: "present" },
      ],

      header_employment: [
        { text: "Employer Name", value: "employer_name" },
        { text: "Job Title", value: "job_title" },
        { text: "Income Amount", value: "income_amount" },
        { text: "Years", value: "time_at_this_company_years" },
        { text: "Months", value: "time_at_this_company_months" },
        { text: "Type", value: "employment_type" },
        { text: "Present", value: "current_job" },
      ],

      header_property: [
        {
          text: "Address",
          value: "address",
        },

        {
          text: "Type",
          value: "prop_style.property_type",
        },

        {
          text: "Purchase Price",
          value: "purchase_price",
        },

        {
          text: "Purchase Date",
          value: "purchase_date",
        },
      ],

      header_liability: [
        { text: "Liability Type", value: "liability_type" },
        { text: "Balance", value: "balance" },
        { text: "Payment", value: "payment" },
        { text: "Maturity Date", value: "maturity_date" },
        { text: "Additional Description", value: "additional_description" },
      ],

      // headers
      header_relationship: [
        { text: "Name", value: "name" },
        { text: "Email", value: "cust_2.email" },
        { text: "Phone number", value: "cust_2.cell_phone_number" },
        { text: "Relationship", value: "type" },
      ],

      header_deals: [
        {
          text: "Public Name",
          align: "start",
          filterable: true,
          value: "deal.public_name",
        },
        {
          text: "Internal Name",
          align: "start",
          filterable: true,
          value: "deal.internal_name",
        },

        {
          text: "Province",
          align: "start",
          filterable: true,
          value: "deal.province",
        },
        {
          text: "Status",
          align: "start",
          filterable: true,
          value: "deal.status",
        },
        {
          text: "Deal Summary Status",
          align: "start",
          filterable: true,
          value: "deal.deal_summary_status",
        },

        {
          text: "Stage",
          align: "start",
          filterable: true,
          value: "deal.kbn_stg.stage_name",
        },
      ],

      rule: {
        required: [(v) => !!v || "This is required"],

        phone: [
          (v) =>
            !v ||
            /\d/g.test(v) ||
            "Phone must be numbers only (ex. 7803334444)",
          (v) =>
            !v ||
            v.length == 10 ||
            v.length == 9 ||
            "Phone number must be 10 digits no spaces or characters (format 7803334444)",
        ],

        email: [
          (v) => (v && !!v) || "E-mail is required",
          (v) => (v && /.+@.+/.test(v)) || "E-mail must be valid",
        ],

        number: [(v) => /^\d+$/.test(v) || "Input must be a number"],

        month: [
          (v) => /^\d+$/.test(v) || "Input must be a number",
          (v) => (v >= 0 && v <= 11) || "Input must be between 0 and 11",
        ],
      },
    };
  },

  methods: {
    async submit_link(data) {
      try {
        let response = null;

        if (this.is_edit_link) {
          response = await API().patch(`api/internal-admin/customer/edit-link`, {
            data: data,
          });
        } else {
          response = await API().post(`api/internal-admin/customer/add-link`, {
            customer_id: this.customer.id,
            data: data,
          });
        }

        console.log(response);

        if (response.status === 201) {
          if (this.is_edit_link) {
            const index = this.customer.links.findIndex(
              (link) => link.id === response.data.link.id
            );

            this.customer.links[index] = { ...response.data.link };
          } else {
            this.customer.links.push(response.data.link);
          }

          this.dialog_link = false;
          this.is_edit_link = false;
        } else {
          this.warning = true;
          this.warningMessage = "Something went wrong in submiting the link.";
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    edit_link(data) {
      this.is_edit_link = true;
      this.dialog_link = true;
      this.$nextTick(() => {
        this.$refs.link_form.setForm(data);
      });
      
    },

    async delete_link(id) {
      try {
        if (window.confirm("Are you sure you want to delete this?")) {
          const response = await API().post(
            `api/internal-admin/customer/delete-link`,
            {
              id: id,
            }
          );

          console.log(response);

          if (response.status === 200) {
            const index = this.customer.links.findIndex((link) => link.id === id);

            this.customer.links.splice(index, 1);
          } else {
            this.warning = true;
            this.warningMessage = "Something went wrong in deleting a link.";
          }
        } else {
          console.log("deleting link canceled");
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    handle_click_property_row(prop) {
      this.$router.push(
        `/dashboard/admin/property/update_from_customer?customer_id=${this.id}&property_id=${prop.id}`
      );
    },

    handle_click_deal(value) {
      this.$router.push(`/dashboard/admin/deal/read/${value.deal.id}`);
    },

    handle_click_associated_customer(value) {
      this.$router.push(`/dashboard/admin/customer/read/${value.cust_2.id}`);
    },

    async handle_save_relationship(value) {
      try {
        const response = await API().post(
          `api/internal-admin/customer/create_relationship`,
          {
            type: value.type,
            cust_1: Number(this.id),
            cust_2: Number(value.cust_2.id),
          }
        );

        console.log(response);

        if (response.status === 201) {
          this.customer.cust_rltns_1.push(response.data);
          this.associating_customer = false;
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong in adding relationship.";
        }
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    address_time_at_residence(data) {
      let totalMonths = 0;

      for (let i = 0; i < data.length; i++) {
        if (data[i].archived === true) {
          continue;
        }
        totalMonths += Number(data[i].years) * 12 + Number(data[i].months);
      }

      const years = Math.floor(totalMonths / 12);
      const months = totalMonths % 12;

      switch (true) {
        case years === 1 && months === 0:
          return { text: "1 year", year: years };
        case years === 1 && months === 1:
          return { text: "1 year and 1 month", year: years };
        case years === 1:
          return { text: `${years} years and ${months} months`, year: years };
        case months === 1:
          return { text: `${years} years and ${months} month`, year: years };
        case months === 0 && years === 0:
          return { text: "0", year: years };
        default:
          return { text: `${years} years and ${months} months`, year: years };
      }
    },

    time_at_job(data) {
      let totalMonthsCompany = 0;

      for (let i = 0; i < data.length; i++) {
        if (data[i].archived === true) {
          continue;
        }
        totalMonthsCompany +=
          Number(data[i].time_at_this_company_years) * 12 +
          Number(data[i].time_at_this_company_months);
      }

      const years = Math.floor(totalMonthsCompany / 12);
      const months = totalMonthsCompany % 12;

      switch (true) {
        case years === 1 && months === 0:
          return { text: "1 year", year: years };
        case years === 1 && months === 1:
          return { text: "1 year and 1 month", year: years };
        case years === 1:
          return { text: `${years} years and ${months} months`, year: years };
        case months === 1:
          return { text: `${years} years and ${months} month`, year: years };
        case months === 0 && years === 0:
          return { text: "0", year: years };
        default:
          return { text: `${years} years and ${months} months`, year: years };
      }
    },

    async handle_save_address(value) {
      try {
        const response = await API().post(
          `api/internal-admin/customer/create_address`,
          {
            address_data: value,
            applicant_id: Number(this.id),
          }
        );

        console.log(response);

        if (response.status === 201) {
          this.customer.addresses.push(response.data);
          this.new_address_data = {};
          this.adding_address = false;
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong in adding a new address.";
        }
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    handle_updating_address(value) {
      if (this.adding_address) {
        alert("Please save the address you are adding before updating it.");
        return;
      } else {
        this.updating_address = true;

        this.new_address_data = value;
      }
    },

    async handle_save_update_address(value) {
      try {
        const response = await API().patch(
          `api/internal-admin/customer/update_address`,
          {
            address_data: value,
          }
        );

        console.log(response);

        if (response.status === 201) {
          const index = this.customer.addresses.findIndex(
            (el) => el.id === value.id
          );

          this.customer.addresses[index] = response.data;

          this.new_address_data = {};
          this.updating_address = false;
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong in updating the address.";
        }
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    handle_delete_address() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_address.forEach(async (el) => {
          try {
            const response = await API().post(
              `api/internal-admin/customer/delete_address`,
              {
                address_id: el.id,
              }
            );

            console.log(response);

            if (response.status === 201) {
              const index = this.customer.addresses.findIndex(
                (address) => address.id === el.id
              );

              this.customer.addresses.splice(index, 1);
            } else {
              this.error = true;
              this.errorMessage =
                "Something went wrong in deleting the address.";
            }
          } catch (error) {
            console.log(error.response.data.message);
            this.error = true;
            this.errorMessage = error.response.data.message;
          }
        });

        this.selected_address = [];
      }
    },

    async handle_save_employment(value) {
      try {
        const response = await API().post(
          `api/internal-admin/customer/create_employment`,
          {
            employment_data: value,
            applicant_id: Number(this.id),
          }
        );

        console.log(response);

        if (response.status === 201) {
          response.data.gross_revenue = this.formatCurrency(
            String(response.data.gross_revenue)
          );
          response.data.income_amount = this.formatCurrency(
            String(response.data.income_amount)
          );

          this.customer.cust_emps.push(response.data);
          this.new_employment_data = {};
          this.adding_employment = false;
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong in adding employment.";
        }
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    handle_updating_employment(value) {
      if (this.adding_employment) {
        alert("Please save the employment you are adding before updating it.");
        return;
      } else {
        this.updating_employment = true;

        this.new_employment_data = value;
      }
    },

    async handle_save_update_employment(value) {
      try {
        const response = await API().patch(
          `api/internal-admin/customer/update_employment`,
          {
            employment_data: value,
          }
        );

        console.log(response);

        if (response.status === 201) {
          const index = this.customer.cust_emps.findIndex(
            (el) => el.id === value.id
          );

          this.customer.cust_emps[index] = response.data;

          this.new_employment_data = {};
          this.updating_employment = false;
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong in updating employment.";
        }
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    handle_delete_employment() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_employment.forEach(async (el) => {
          try {
            const response = await API().post(
              `api/internal-admin/customer/delete_employment`,
              {
                employment_id: el.id,
              }
            );

            console.log(response);

            if (response.status === 201) {
              const index = this.customer.cust_emps.findIndex(
                (employment) => employment.id === el.id
              );

              this.customer.cust_emps.splice(index, 1);
            } else {
              this.error = true;
              this.errorMessage =
                "Something went wrong in deleting the employment.";
            }
          } catch (error) {
            console.log(error.response.data.message);
            this.error = true;
            this.errorMessage = error.response.data.message;
          }
        });

        this.selected_employment = [];
      }
    },

    handle_unassociate_property() {
      if (
        window.confirm(
          `Are you sure you want to unassociate these properties from this customer?`
        )
      ) {
        this.selected_property.forEach(async (el) => {
          try {
            const response = await API().post(
              `api/internal-admin/customer/unassociate_customer_to_property`,
              {
                prop_id: el.id,
                customer_id: Number(this.id),
              }
            );

            console.log(response);

            if (response.status === 201) {
              const index = this.customer.props.findIndex(
                (property) => property.id === el.id
              );

              this.customer.props.splice(index, 1);
            } else {
              this.error = true;
              this.errorMessage =
                "Something went wrong in unassociating a property.";
            }
          } catch (error) {
            console.log(error);
            this.selected_property = [];
          }
        });

        this.selected_property = [];
      } else {
        console.log(`Unassociating properties from customer cancelled.`);
        this.selected_property = [];
      }
    },

    async handle_save_liability(value) {
      try {
        const response = await API().post(
          `api/internal-admin/customer/create_liability`,
          {
            liability_data: value,
            applicant_id: Number(this.id),
          }
        );

        console.log(response);

        if (response.status === 201) {
          response.data.balance = this.formatCurrency(
            String(response.data.balance)
          );

          response.data.payment = this.formatCurrency(
            String(response.data.payment)
          );

          this.customer.cust_liabs.push(response.data);
          this.new_liability_data = {};
          this.adding_liability = false;
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong in adding liability.";
        }
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    handle_updating_liability(value) {
      if (this.adding_liability) {
        alert("Please save the liability you are adding before updating it.");
        return;
      } else {
        this.updating_liability = true;
        this.new_liability_data = value;
      }
    },

    async handle_save_update_liability(value) {
      try {
        const response = await API().patch(
          `api/internal-admin/customer/update_liability`,
          {
            liability_data: value,
          }
        );

        console.log(response);

        if (response.status === 201) {
          response.data.balance = this.formatCurrency(
            String(response.data.balance)
          );

          response.data.payment = this.formatCurrency(
            String(response.data.payment)
          );

          const index = this.customer.cust_liabs.findIndex(
            (el) => el.id === value.id
          );

          this.customer.cust_liabs[index] = response.data;

          this.new_liability_data = {};
          this.updating_liability = false;
        } else {
          this.error = true;
          this.errorMessage = "Something went wrong in updating liability.";
        }
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    handle_delete_liability() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_liability.forEach(async (el) => {
          try {
            const response = await API().post(
              `api/internal-admin/customer/delete_liability`,
              {
                liability_id: el.id,
              }
            );

            console.log(response);

            if (response.status === 201) {
              const index = this.customer.cust_liabs.findIndex(
                (liability) => liability.id === el.id
              );

              this.customer.cust_liabs.splice(index, 1);
            } else {
              this.error = true;
              this.errorMessage =
                "Something went wrong in deleting the liability.";
            }
          } catch (error) {
            console.log(error.response.data.message);
            this.error = true;
            this.errorMessage = error.response.data.message;
          }
        });

        this.selected_liability = [];
      }
    },

    async handle_selected_deal(value) {
      if (
        window.confirm(
          `Are you sure you want ${value.internal_name} to be associated with this customer?`
        )
      ) {
        try {
          const response = await API().post(
            `api/internal-admin/customer/associate_customer_to_deal`,
            {
              deal_id: value.id,
              customer_id: Number(this.id),
            }
          );

          console.log(response);

          if (response.status === 201) {
            this.customer.int_custs.push(response.data);
          } else {
            this.error = true;
            this.errorMessage =
              "Something went wrong in associating a new deal.";

            setTimeout(() => {
              this.error = false;
              this.errorMessage = "";
            }, 5000);
          }
        } catch (error) {
          console.log(error.response.data.message);
          this.error = true;
          this.errorMessage = error.response.data.message;
        }
      } else {
        console.log(
          `Associating ${value.internal_name} to customer cancelled.`
        );
      }
    },

    async handle_unassociate_deals() {
      if (
        window.confirm(
          `Are you sure you want to unassociate these deals from this customer?`
        )
      ) {
        try {
          this.selected_deal.forEach(async (el) => {
            const response = await API().post(
              `api/internal-admin/customer/unassociate_customer_to_deal`,
              {
                int_cust_id: el.id,
              }
            );

            console.log(response);

            if (response.status === 201) {
              const index = this.customer.int_custs.findIndex(
                (int_cust) => int_cust.id === el.id
              );

              this.customer.int_custs.splice(index, 1);
            } else {
              this.error = true;
              this.errorMessage =
                "Something went wrong in unassociating a deal.";

              setTimeout(() => {
                this.error = false;
                this.errorMessage = "";
              }, 5000);
            }
          });

          this.selected_deal = [];
        } catch (error) {
          console.log(error.response.data.message);
          this.error = true;
          this.errorMessage = error.response.data.message;
          this.selected_deal = [];
        }
      } else {
        console.log(`Unassociating deals from customer cancelled.`);
        this.selected_deal = [];
      }
    },

    formatCurrency(amount) {
      let currencyValue = 0;

      if (amount !== 0) {
        // Remove commas and dollar signs
        let unformattedValue = amount.replace(/[$,]/g, "");

        // Parse the input value as a number
        currencyValue = parseInt(unformattedValue) || 0;

        // Format the number with commas and a dollar sign
        let formatted_amount = currencyValue
          .toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
          .replace(".00", "");

        return formatted_amount;
      } else {
        return 0;
      }
    },

    uppercase_first_letter(value) {
      value.charAt(0).toUpperCase() + value.slice(1);
    },

    async getData() {
      try {
        this.loading = true;

        // Get customer information
        const response = await API().get(
          `api/internal-admin/customer/read_one_customer?customer_id=${Number(
            this.id
          )}`
        );

        // console.log(response);

        this.customer = response.data; // Set customers to response data

        if (response.status === 200) {
          for (const employment of this.customer.cust_emps) {
            employment.gross_revenue = this.formatCurrency(
              String(employment.gross_revenue)
            );

            employment.income_amount = this.formatCurrency(
              String(employment.income_amount)
            );
          }

          for (const liability of this.customer.cust_liabs) {
            liability.balance = this.formatCurrency(String(liability.balance));

            liability.payment = this.formatCurrency(String(liability.payment));
          }

          // Get all customers
          const response = await API().get(
            `api/internal-admin/customer/read_all_customers`
          );

          this.customers = response.data; // Set customers to response data
        }
        this.loading = false;
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
        this.loading = false;

        setTimeout(() => {
          this.$router.push("/dashboard/admin/customer/list-all-customers");
        }, 5000);
      }
    },
  },

  filters: {
    ...filt,
  },

  created() {
    this.getData();
  },
};
</script>

<style>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>
